// Define a type for the banner templates
type BannerTemplate = 'default' | 'secondary' // Add more template types as needed

// Define the type for the function that handles banner placements
type BannerPlacementFunction = (items: any[]) => any[]

/**
 * Hook to manage banner placements based on the page type and content type.
 *
 * @param {any} data - The data used to determine the content type.
 * @returns {object} - An object containing the useCreateBannerPlacements function, contentTypeConfig, and contentType.
 */
export function useBannerPlacement(data: any) {
  const appConfig: any = useAppConfig()
  const contentStore = useContentStore()
  const { pageType } = contentStore

  let contentTypeConfig: any, contentType: string
  if (pageType === 'content') {
    ;({ contentTypeConfig, contentType } = useContentType())
  } else {
    contentType =
      pageType === 'page'
        ? appConfig.page.bannerTemplate || 'default'
        : appConfig.category.bannerTemplate || 'default'
  }

  const { compositePlacements, pagePlacements, categoryPlacements } =
    useCreateBannerTemplate()

  /**
   * Function to create banner placements based on the items provided.
   *
   * @param {any[]} items - The items to place banners on.
   * @returns {any[]} - The items with banners placed.
   */
  function useCreateBannerPlacements(items: any[]): any[] {
    /**
     * Get the banner template based on the page type and content type.
     *
     * @returns {BannerTemplate} - The banner template to use.
     */
    const getBannerTemplate = (): BannerTemplate => {
      if (pageType === 'content') {
        return contentTypeConfig.value?.bannerTemplate || 'default'
      }
      return pageType === 'page'
        ? appConfig.page?.bannerTemplate || 'default'
        : appConfig.category?.bannerTemplate || 'default'
    }

    /**
     * Get the banner placement function based on the banner template.
     *
     * @param {BannerTemplate} bannerTemplate - The banner template to use.
     * @returns {BannerPlacementFunction} - The function to place banners.
     */
    const getBannerPlacementFunction = (
      bannerTemplate: BannerTemplate
    ): BannerPlacementFunction => {
      if (pageType === 'content') {
        return (
          compositePlacements[bannerTemplate + 'BannerPlacement'] ||
          compositePlacements.defaultBannerPlacement
        )
      } else if (pageType === 'page') {
        return pagePlacements.frontPagePlacements
      } else if (pageType === 'category') {
        return categoryPlacements.categoryPagePlacement
      }
      return compositePlacements.defaultBannerPlacement
    }

    const bannerTemplate = getBannerTemplate()
    const bannerPlacementFunction = getBannerPlacementFunction(bannerTemplate)
    const test = bannerPlacementFunction(items)
    return bannerPlacementFunction(items)
  }

  return { useCreateBannerPlacements }
}
