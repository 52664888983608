/**
 * Interface representing an advertisement.
 */
interface Ad {
  adNode: Element
  published: boolean
  nodeId: number
}

/**
 * Interface for configuration settings.
 */
interface Config {
  pageType: string
  device: string
  spa: boolean
  targeting?: {
    tags: string[]
  }
}

/**
 * Interface for the Bau object with methods for ad display and initialization.
 */
interface Bau {
  displayAds(target: HTMLElement & { published: boolean }): unknown
  init: (data: any, config: Config) => void
  preloadAds: (data: any, config: Config) => void
  // Add other methods of Bau as needed
}

/**
 * Extends the global Window interface to include the Bau object.
 */
declare global {
  interface Window {
    Bau: Bau
  }
}

/**
 * Hook for using Bau functionality within a Vue component.
 *
 * @returns An object containing the BauInit function.
 */
export function useBau() {
  /**
   * Uses the device type hook to determine the current device.
   */
  const { currentDevice } = useDeviceType()
  /**
   * A reactive reference to an array of ads.
   */
  const ads = ref<Ad[]>([])
  /**
   * IntersectionObserver for detecting when ads enter the viewport.
   */
  let observer: IntersectionObserver

  /**
   * Asynchronously fetches BAM data based on the current URL and device.
   *
   * @returns A Promise resolving to the fetched BAM data.
   */
  const getBamData = async (): Promise<any> => {
    const baseUrl = location.href
    const url = `${baseUrl}?device=${currentDevice}`
    const bamData = await $fetch<any>(`https://bam.bonad.io/?url=${url}`)
    return bamData
  }

  /**
   * Initializes the Bau object with configuration and BAM data, then sets up ads.
   */
  const BauInit = async () => {
    const bamData: any = await getBamData()

    const contentStore = useContentStore()
    const { getAllTags, pageType, content } = contentStore

    const currentPageType = computed(() => {
      return pageType === 'content' ? 'article' : 'section'
    })
    const tags = getAllTags()
    const commercial = content?.commercial?.type || null

    const config: Config = {
      pageType: currentPageType.value,
      device: currentDevice,
      spa: true,
    }
    if (commercial || (tags && tags.length > 0)) {
      config.targeting = {
        tags: [commercial, ...tags].filter(Boolean) as string[],
      }
    }

    window.Bau.init(bamData, config)
    window.Bau.preloadAds(window.document.body)
    initializeAds()
    setTimeout(() => {
      resizeAds()
    }, 2000)

    window.addEventListener('resize', resizeAds)
  }

  /**
   * Initializes ad elements by setting their attributes and adding them to the ads array.
   */
  const initializeAds = () => {
    const adElements = document.querySelectorAll('.bp-ads')

    adElements.forEach((adElement, index) => {
      adElement.setAttribute('data-ad-id', index.toString())

      ads.value.push({
        adNode: adElement,
        published: false,
        nodeId: index,
      })
    })
    displayVisibleAds()
  }

  /**
   * Sets up an IntersectionObserver to display ads when they become visible.
   */
  const displayVisibleAds = () => {
    const options = {
      root: null,
      rootMargin: '300px',
      threshold: 0,
    }
    observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement & { published: boolean }
        if (entry.isIntersecting && !target.published) {
          target.published = true
          window.Bau.displayAds(target)
        }
      })
    }, options)

    ads.value.forEach(ad => {
      if (!ad.published) {
        observer.observe(ad.adNode)
      }
    })
  }

  /**
   * Resizes all ads based on their container's width.
   */
  const resizeAds = () => {
    ads.value.forEach((ad, index) => {
      resizeAd(ad, index)
    })
  }

  /**
   * Resizes a single ad based on its container's width.
   *
   * @param adData - The ad data to resize.
   * @param index - The index of the ad in the ads array.
   */
  const resizeAd = (adData: Ad, index: number) => {
    const bauSlot = adData.adNode.getAttribute('data-slot-name')
    if (bauSlot === 'panorama') {
      if (!adData) return

      const element = document.querySelector(`[data-ad-id="${index}"]`)

      if (!(element instanceof HTMLElement)) return

      const inner = element.firstElementChild as HTMLElement
      if (!inner) return

      const width = 970
      const maxWidth = element.parentElement?.offsetWidth
      if (maxWidth === undefined || isNaN(maxWidth)) return

      if (width <= maxWidth) {
        inner.style.removeProperty('webkitTransform')
        inner.style.removeProperty('transform')
        element.style.height = 'auto'
        return
      }

      const scale = maxWidth / width
      inner.style.webkitTransform = inner.style.transform = `scale(${scale})`
      element.style.minHeight = 'auto'
    }
  }

  return { BauInit }
}
