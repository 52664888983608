export function usePiano() {
  const contentStore = useContentStore()
  const { pageType, getAllTags, content, userState } = contentStore
  const { contentType } = useContentType()
  const { ParsedEvents } = useParsedEvents()

  const tpPush = (args: any[]) => {
    return new Promise<void>(resolve => {
      window.tp.push([...args, resolve])
    })
  }

  const PianoSetup = async () => {
    tpPush(['setZone', pageType])
    tpPush(['setTags', getAllTags()])

    if (pageType === 'content') {
      const customVariables = {
        compositeTitle: content?.title,
        compositeDescription: content?.description,
        userState: userState,
      }
      // Iterate over the customVariables object and set each variable individually
      for (const [key, value] of Object.entries(customVariables)) {
        tpPush(['setCustomVariable', key, value])
      }
      tpPush(['setContentSection', contentType.value])
    } else {
      tpPush(['setContentSection', ''])
    }
  }

  const PianoInit = async () => {
    await PianoSetup()
    window.tp.experience.execute()

    window.tp.push([
      'addHandler',
      'checkoutCustomEvent',
      event => {
        ParsedEvents(event)
      },
    ])
  }

  return { PianoInit, PianoSetup }
}
