<script setup lang="ts">
  import PartialTeaserListTheme from '../../Partials/TeaserList/TeaserListTheme.vue'
  import PartialTeaserListOrdered from '../../Partials/TeaserList/TeaserListOrdered.vue'
  const { recommendationData } = defineProps<{
    recommendationData: object
  }>()

  const categoryId = recommendationData.id
  const recomendedItems = recommendationData['content-teasers']?.data || []

  const config = useRuntimeConfig()
  const url = basicUrl(config?.public?.API_URL)

  const { data: contentPopular } = await useFetch<any>(
    //`https://admin.woman.dk/wp-json/app/content/popular`
    `${url}/app/content/popular?categories=${categoryId}`
  )

  // Create a computed property to transform recomendedItems into TeaserListWrapper
  const contentTeaserWrapper = computed<TeaserListWrapper>(() => ({
    title: recommendationData?.name,
    label: null,
    link_label: null,
    category: {
      id: categoryId,
    },
    tag: null,
    display_hint: 'theme',
    theme: 'light',
    can_paginate: false,
    teasers: {
      data: recomendedItems,
    },
  }))

  const contentRecommendationWrapper = computed<TeaserListWrapper>(() => ({
    title: 'Mest Sete', // TODO replace with translation string
    label: null,
    link_label: null,
    category: {
      id: categoryId,
    },
    tag: null,
    display_hint: 'ordered_list',
    theme: 'light',
    can_paginate: false,
    teasers: {
      data: contentPopular.value.data.slice(0, 4),
    },
  }))
</script>

<template>
  <div class="gap-13">
    <PartialTeaserListTheme
      data-component-type="teaser_list"
      data-component-name="theme"
      :teaserData="contentTeaserWrapper"
    />
    <div id="mkt-placement-17" class="main-content"></div>
    <PartialTeaserListOrdered
      data-component-type="teaser_list"
      data-component-name="ordered_list"
      v-if="contentPopular.length > 0"
      :teaserData="contentRecommendationWrapper"
    />
  </div>
</template>
