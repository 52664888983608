// composables/usePianoEvents.ts

async function handleNewsletterSignup(event: any) {
  const { params } = event
  const { customBrandCode, customSourceCode, email, name, qualifiox3 } = params

  // Prepare the data to be sent
  const data = {
    name,
    email,
    customBrandCode,
    customSourceCode,
    qualifiox3,
  }

  try {
    // Perform the $fetch POST request
    await $fetch('/api/Newsletter', {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    console.error('Error:', error)
  }
}

// Add more event handlers as needed

export function useParsedEvents() {
  function ParsedEvents(event: any) {
    const eventHandlers: { [key: string]: (event: any) => void } = {
      'newsletter-signup': handleNewsletterSignup,
      // Add more event handlers to the mapping as needed
    }

    const customEventName = event.customEventName
    const handler = eventHandlers[customEventName]

    if (handler) {
      handler(event)
    } else {
      console.warn(`No handler found for event: ${customEventName}`)
    }
  }

  return { ParsedEvents }
}
