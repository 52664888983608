/**
 * @typedef {'default' | 'special'} BannerTemplate - Type for the banner templates.
 */

/**
 * @typedef {function(any[]): any[]} BannerPlacementFunction - Type for the function that handles banner placements.
 */

// Get the banner data
const { usePanoramaBannerData } = useBannerSlots()

/**
 * Generic function to handle banner placements.
 *
 * @param {function(number): boolean} condition - A function that takes an index and returns a boolean.
 * @returns {BannerPlacementFunction} - A function that takes an array of items and returns a new array with banners placed.
 */
function createBannerPlacementFunction(
  condition: (index: number) => boolean
): BannerPlacementFunction {
  return (items: any[]): any[] => {
    return items.reduce((acc, item, index) => {
      acc.push(item)
      if (condition(index)) {
        acc.push(usePanoramaBannerData.value)
      }
      return acc
    }, [])
  }
}

/**
 * Factory function to create and return banner placement functions.
 *
 * @returns {Object} - An object containing different banner placement functions.
 */
export function useCreateBannerTemplate() {
  return {
    compositePlacements: {
      /**
       * Default banner placement function.
       *
       * @type {BannerPlacementFunction}
       */
      defaultBannerPlacement: createBannerPlacementFunction(
        index => index < 2 || (index - 1) % 2 === 0
      ),
    },
    pagePlacements: {
      /**
       * Front page banner placement function.
       *
       * @type {BannerPlacementFunction}
       */
      frontPagePlacements: createBannerPlacementFunction(
        index => (index + 1) % 2 === 0
      ),
    },
    categoryPlacements: {
      /**
       * Category page banner placement function.
       *
       * @type {BannerPlacementFunction}
       */
      categoryPagePlacement: createBannerPlacementFunction(
        index => index === 0 || (index - 1) % 2 === 0
      ),
    },
  }
}
