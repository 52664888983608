<script setup lang="ts">
  import RecommendationDefault from './Partials/Recommendation/RecommendationDefault.vue'

  const componentMap: ComponentMap = {
    default: RecommendationDefault,
  }

  const recommendationData = defineProps<{
    data: RecommendationData
    type?: string
  }>()

  const selectedComponent = computed(
    () => componentMap[recommendationData.type] || RecommendationDefault
  )
</script>

<template>
  <section
    class="wide-content my-15 bp-main-grid"
    data-position="Below content"
  >
    <div class="main-content">
      <component
        :is="selectedComponent"
        :recommendationData="recommendationData?.data"
      />
    </div>
  </section>
</template>
